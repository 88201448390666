
// FADE
@-webkit-keyframes fade {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}
@-moz-keyframes fade {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}
@-o-keyframes fade {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}
@keyframes fade {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}


// UPPER
@-webkit-keyframes upper {
  0%   {top: 9px;}
  50%  {top: 13.5px; transform: rotate(0deg);}
  100% {top: 13.5px; transform: rotate(43deg);}
}
@-moz-keyframes upper {
  0%   {top: 9px;}
  50%  {top: 13.5px; transform: rotate(0deg);}
  100% {top: 13.5px; transform: rotate(43deg);}
}
@-o-keyframes upper {
  0%   {top: 9px;}
  50%  {top: 13.5px; transform: rotate(0deg);}
  100% {top: 13.5px; transform: rotate(43deg);}
}
@keyframes upper {
  0%   {top: 9px;}
  50%  {top: 13.5px; transform: rotate(0deg);}
  100% {top: 13.5px; transform: rotate(43deg);}
}


// UPPER REVERSE
@-webkit-keyframes upperReverse {
    0%   {top: 13.5px; transform: rotate(43deg);}
    50%  {top: 13.5px; transform: rotate(0deg);}
    100% {top: 9px;}
}
@-moz-keyframes upperReverse {
    0%   {top: 13.5px; transform: rotate(43deg);}
    50%  {top: 13.5px; transform: rotate(0deg);}
    100% {top: 9px;}
}
@-o-keyframes upperReverse {
    0%   {top: 13.5px; transform: rotate(43deg);}
    50%  {top: 13.5px; transform: rotate(0deg);}
    100% {top: 9px;}
}
@keyframes upperReverse {
    0%   {top: 13.5px; transform: rotate(43deg);}
    50%  {top: 13.5px; transform: rotate(0deg);}
    100% {top: 9px;}
}


// LOWER
@-webkit-keyframes lower {
    0%   {bottom: 9px;}
    50%  {bottom: 13.5px; transform: rotate(0deg);}
    100% {bottom: 13.5px; transform: rotate(-43deg);}
}
@-moz-keyframes lower {
    0%   {bottom: 9px;}
    50%  {bottom: 13.5px; transform: rotate(0deg);}
    100% {bottom: 13.5px; transform: rotate(-43deg);}
}
@-o-keyframes lower {
    0%   {bottom: 9px;}
    50%  {bottom: 13.5px; transform: rotate(0deg);}
    100% {bottom: 13.5px; transform: rotate(-43deg);}
}
@keyframes lower {
    0%   {bottom: 9px;}
    50%  {bottom: 13.5px; transform: rotate(0deg);}
    100% {bottom: 13.5px; transform: rotate(-43deg);}
}


// LOWER REVERSE
@-webkit-keyframes lowerReverse {
    0%   {bottom: 13.5px; transform: rotate(-43deg);}
    50%  {bottom: 13.5px; transform: rotate(0deg);}
    100% {bottom: 9px;}
}
@-moz-keyframes lowerReverse {
    0%   {bottom: 13.5px; transform: rotate(-43deg);}
    50%  {bottom: 13.5px; transform: rotate(0deg);}
    100% {bottom: 9px;}
}
@-o-keyframes lowerReverse {
    0%   {bottom: 13.5px; transform: rotate(-43deg);}
    50%  {bottom: 13.5px; transform: rotate(0deg);}
    100% {bottom: 9px;}
}
@keyframes lowerReverse {
    0%   {bottom: 13.5px; transform: rotate(-43deg);}
    50%  {bottom: 13.5px; transform: rotate(0deg);}
    100% {bottom: 9px;}
}


// SLIDE UP
@-webkit-keyframes slideup {
  0% {transform: translateY(100px);} 
  100% {transform: translateY(0);}
}
@-moz-keyframes slideup {
  0% {transform: translateY(100px);} 
  100% {transform: translateY(0);}
}
@-o-keyframes slideup {
  0% {transform: translateY(100px);} 
  100% {transform: translateY(0);}
}
@keyframes slideup {
  0% {transform: translateY(100px);} 
  100% {transform: translateY(0);}
}


// SLIDE UP FADE
@-webkit-keyframes slideupfade {
    0% {opacity: 0; transform: translateY(100px);}  
    100% {opacity: 1; transform: translateY(0);}
}
@-moz-keyframes slideupfade {
    0% {opacity: 0; transform: translateY(100px);}  
    100% {opacity: 1; transform: translateY(0);}
}
@-o-keyframes slideupfade {
    0% {opacity: 0; transform: translateY(100px);}  
    100% {opacity: 1; transform: translateY(0);}
}
@keyframes slideupfade {
    0% {opacity: 0; transform: translateY(100px);}  
    100% {opacity: 1; transform: translateY(0);}
}


//PULSE
@-webkit-keyframes pulse {
  0% {transform: scale(0.9) rotate(45deg); opacity: 0.7;}
	50% {transform: scale(1) rotate(45deg); opacity: 1;}	
	100% {transform: scale(0.9) rotate(45deg); opacity: 0.7;}	
}
@-moz-keyframes pulse {
  0% {transform: scale(0.9) rotate(45deg); opacity: 0.7;}
	50% {transform: scale(1) rotate(45deg); opacity: 1;}	
	100% {transform: scale(0.9) rotate(45deg); opacity: 0.7;}	
}
@-o-keyframes pulse {
  0% {transform: scale(0.9) rotate(45deg); opacity: 0.7;}
	50% {transform: scale(1) rotate(45deg); opacity: 1;}	
	100% {transform: scale(0.9) rotate(45deg); opacity: 0.7;}	
}
@keyframes pulse {
  0% {transform: scale(0.9) rotate(45deg); opacity: 0.7;}
	50% {transform: scale(1) rotate(45deg); opacity: 1;}	
	100% {transform: scale(0.9) rotate(45deg); opacity: 0.7;}	
}


//FLOATING
@-webkit-keyframes floating {
  0% {transform: translateY(0%);}
	50% {transform: translateY(50%);}	
	100% {transform: translateY(0%);}	
}
@-moz-keyframes floating {
  0% {transform: translateY(0%);}
	50% {transform: translateY(50%);}	
	100% {transform: translateY(0%);}	
}
@-o-keyframes floating {
  0% {transform: translateY(0%);}
	50% {transform: translateY(50%);}	
	100% {transform: translateY(0%);}	
}
@keyframes floating {
  0% {transform: translateY(0%);}
	50% {transform: translateY(50%);}	
	100% {transform: translateY(0%);}	
}


// OPEN MENU
@-webkit-keyframes openmenu {
  0% { transform: translateX(calc(-100vw + #{$header-height}));}    
  100% {transform: translateX(0);}
}
@-moz-keyframes openmenu {
  0% { transform: translateX(calc(-100vw + #{$header-height}));}    
  100% {transform: translateX(0);}
}
@-o-keyframes openmenu {
  0% { transform: translateX(calc(-100vw + #{$header-height}));}    
  100% {transform: translateX(0);}
}
@keyframes openmenu {
  0% { transform: translateX(calc(-100vw + #{$header-height}));}    
  100% {transform: translateX(0);}
}


// CLOSE MENU
@-webkit-keyframes closemenu {
  0% {transform: translateX(0);}
  100% {transform: translateX(calc(-100vw + #{$header-height}));}
}
@-moz-keyframes closemenu {
  0% {transform: translateX(0);}
  100% {transform: translateX(calc(-100vw + #{$header-height}));}
}
@-o-keyframes closemenu {
  0% {transform: translateX(0);}
  100% {transform: translateX(calc(-100vw + #{$header-height}));}
}
@keyframes closemenu {
  0% {transform: translateX(0);}
  100% {transform: translateX(calc(-100vw + #{$header-height}));}
}


// MENU ITEM
@-webkit-keyframes menuItem {
  0%   { opacity: 0; }
  35%   { opacity: 0; }
  100% { opacity: 1; }
}
@-moz-keyframes menuItem {
  0%   { opacity: 0; }
  35%   { opacity: 0; }
  100% { opacity: 1; }
}
@-o-keyframes menuItem {
  0%   { opacity: 0; }
  35%   { opacity: 0; }
  100% { opacity: 1; }
}
@keyframes menuItem {
  0%   { opacity: 0; }
  35%   { opacity: 0; }
  100% { opacity: 1; }
}


// FORM ERROR
@-webkit-keyframes formError {
  0% {opacity: 0; transform: translateY(-10px);}  
  100% {opacity: 1; transform: translateY(0);}
}
@-moz-keyframes formError {
  0% {opacity: 0; transform: translateY(-10px);}  
  100% {opacity: 1; transform: translateY(0);}
}
@-o-keyframes formError {
  0% {opacity: 0; transform: translateY(-10px);}  
  100% {opacity: 1; transform: translateY(0);}
}
@keyframes formError {
  0% {opacity: 0; transform: translateY(-10px);}  
  100% {opacity: 1; transform: translateY(0);}
}
