// TYPOGRAPHY
$font-family: 'Poppins', sans-serif;

// LAYOUT
$header-height: 50px;
$gutter: 15px;
$container-width: 1500px;
$container-narrow: 900px;

// COLORS
$primary-color: #2e89be;
$primary-color-dark: #216892;
$primary-color-light: #e1f4ff;

$secondary-color: rgb(97, 66, 38);
$secondary-color-dark: rgb(66, 45, 25);
$secondary-color-light: rgb(179, 126, 76);

$dark-color: rgb(20, 20, 20);
$dark-color-dark: black;
$dark-color-light: rgb(36, 36, 36);

$light-color: whitesmoke;
$light-color-dark: rgb(218, 218, 218);
$light-color-light: white;

$danger-color: rgb(231, 102, 102);
$danger-color-dark: rgb(230, 68, 68);