/* You can add global styles to this file, and also import other style files */
@import 'src/variables';
@import 'src/animations';

*, *:after, *:before {
  box-sizing: border-box;
}

body {
  width: 100vw;
  margin: 0;
  overflow-x: hidden;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

*:focus {
  outline: none;
}

a {
  transition: color 1s ease;
}

p {
  font-size: 14px;
  line-height: 28px;
}

label {
  display: flex;
  align-items: center;
  margin-right: auto;
  color: $dark-color;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 11px;
}

input:not([type=radio]), textarea {
  font-family: $font-family;
  padding: 15px;
  border: none;
  background-color: rgba($color: white, $alpha: 0.05);
  width: 100%;
  color: $dark-color;
  transition: background-color 1s ease;
  border: solid 2px rgba($color: $dark-color, $alpha: 0.3);

  &:focus {
    background-color: rgba($color: $dark-color, $alpha: 0.05);
  }
}


*::placeholder {
  text-transform: uppercase;
  font-weight: 600;
}

input.ng-invalid.ng-touched, textarea.ng-invalid.ng-touched {
  border: solid 2px rgba($color: $danger-color, $alpha: 0.3);
}

textarea {
  min-height: 150px;
}
